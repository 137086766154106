import { isDevelopment } from "@biblioteksentralen/utils";
import { Role } from "@sanity/types";
import { useRouter } from "next/router";
import Script from "next/script";

import { isBibsentEmail, isE2eTestEmail, isGlobalAdmin, isRediaEmail } from "@libry-content/common";
import { useSanityAuth } from "../../components/sanityAuth/useSanityAuth";
import { isProductionDataset } from "../currentDataset";

// This is a temporary heuristic-ish solution to get some idea of the real usage until we get an improved analytics setup
export const getDataDomains = (
  topDomain: string,
  isAuthenticated: boolean,
  email?: string,
  roles?: Role[],
  siteDomain?: string
): string[] => {
  if (isBibsentEmail(email) || isRediaEmail(email) || isGlobalAdmin({ roles })) {
    // Intern trafikk fra Biblioteksentralen og Redia
    return [`intern.${topDomain}`];
  }
  if (isAuthenticated) {
    // Innloggede brukere
    return [`editor.${topDomain}`];
  }
  if (siteDomain) {
    // Logger både til eget dashboard for biblioteket og til felles dashboard for alle bibliotek
    // https://plausible.io/docs/plausible-script#is-there-a-roll-up-view
    return [siteDomain, `felles.${topDomain}`];
  }

  return [topDomain];
};

const isJestTest = process.env.NODE_ENV === "test";

export function PlausibleSetup() {
  const { asPath, query } = useRouter();
  const siteDomain = query.site as string | undefined;
  const isSplashPage = !siteDomain && asPath === "/";
  const { loading, error, email, roles, isAuthenticated } = useSanityAuth();

  // Don't mount until we are ready to check the user
  if (loading || error) return null;
  // We don't send metrics to plausible for certain scenarios
  if (isE2eTestEmail(email) || isJestTest || isMountedInIframe()) return null;

  const topDomain = getPlausibleDomain(isSplashPage);
  const dataDomains = getDataDomains(topDomain, isAuthenticated, email, roles, siteDomain);

  return (
    <Script defer data-domain={dataDomains.join(",")} src="https://plausible.io/js/plausible.local.outbound-links.js" />
  );
}

export function getPlausibleDomain(isSplashPage?: boolean): string {
  const splashPagePrefix = isSplashPage ? "splash." : "";

  if (isProductionDataset && !isDevelopment()) return `${splashPagePrefix}bibliotek.io`;

  if (isDevelopment()) return `${splashPagePrefix}bibliotek-local.io`;

  return `${splashPagePrefix}bibliotek-test.io`;
}

type ExtraData = Record<string, string | number | boolean | undefined>;

// https://plausible.io/docs/custom-event-goals
export function logEvent(event: string, extraData?: ExtraData) {
  isDevelopment() && console.log(event, extraData);

  try {
    /** @ts-ignore */
    typeof plausible !== "undefined" && plausible(event, extraData ? { props: extraData } : undefined);
  } catch (e) {
    console.error(e);
  }
}

export function logClick(label: string, extraData: ExtraData = {}) {
  logEvent("Click", { label, ...extraData });
}

export function logAdminClick(label: string, extraData: ExtraData = {}) {
  logEvent("AdminClick", { label, ...extraData });
}

// Ikke telle med sidevisninger når siden vises i en iframe (som feks på /dev/sites, og i preview i editor) https://stackoverflow.com/questions/326069/how-to-identify-if-a-webpage-is-being-loaded-inside-an-iframe-or-directly-into-t
const isMountedInIframe = (): boolean | undefined => {
  if (typeof window === "undefined") return undefined;
  try {
    return window.self !== window.top;
  } catch (e) {
    return undefined;
  }
};
