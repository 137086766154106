import { Progress, useBoolean } from "@biblioteksentralen/react";
import { DEFAULT_LANGUAGE_CODE, isLanguageCode, LanguageCode, LocalizedField } from "@libry-content/localization";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useMount } from "react-use";

// At this point we can't always expect next-translate to be initialised,
// so we use hardcoded strings rather than `t()` from `useTranslation()`
const loadingText: LocalizedField<string> = { nb: "Laster side", nn: "Lastar side" };

export const NavigationProgressBar = () => {
  const [isNavigating, { on: onNavigation, off: onNavigationComplete }] = useBoolean(true);

  // If we use `useTranslation()` to get lang here, we get an error from i18next-parser (used to find unused strings)
  const { locales } = useRouter();
  const lang = locales?.find((code) => isLanguageCode(code)) ?? (DEFAULT_LANGUAGE_CODE as LanguageCode);
  const { events } = useRouter();

  useEffect(() => {
    events.on("routeChangeStart", onNavigation);
    events.on("routeChangeComplete", onNavigationComplete);
    events.on("routeChangeError", onNavigationComplete);

    return () => {
      events.off("routeChangeStart", onNavigation);
      events.off("routeChangeComplete", onNavigationComplete);
      events.off("routeChangeError", onNavigationComplete);
    };
  });

  useMount(onNavigationComplete);

  if (!isNavigating) return null;

  //TODO: Do we need to make sure changes are announced to screen reader? (Chakra Progress component has role=progressbar, which is a live region)

  return (
    <Progress
      isIndeterminate
      height="0.15rem"
      backgroundColor="transparent"
      position="fixed"
      top="0"
      left="0"
      width="100%"
      zIndex="100"
      sx={{ div: { animationDuration: "1.5s" } }}
    >
      {loadingText[lang]}
    </Progress>
  );
};
